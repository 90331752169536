import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminDataOfLcRejection from './AdminDataOfLcRejection';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import { fetchToGetLcRejectionData } from '../../../reduxStore/LcRejection/LcRejectionSlice';
import FormatTime from '../../../assets/FormatTime';

const AdminLcRejection = () => {
  const state = useSelector((state) => state.lcRejectionReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfDataFetched, setTotalNumberOfDataFetched] = useState() // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const [queryParams, setQueryParams] = useState({});

  const extraFilters = {
    "lcId": "LC ID",
    "reasonOfRejection": "Reason Of Rejection",
    "lcRejectedBy": "Input Person Name"
  };

  useEffect(() => {
    if (state?.data?.dataOfAllRejectedLc) {
      setAllData(state.data.allFilteredData);
      setTotalNumberOfDataFetched(state.data.totalNoOfDataFiltered); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "LC ID": item.lcId,
      "Reason Of Rejection": item.reasonOfRejection,
      "LC Rejected By": item.lcRejectedBy,
      "Lc Quantity": item.previousStatusTillLCPreparation.lcQty,
      "SC ID": item.previousStatusTillLCPreparation.scId,
      "SC Quantity": item.previousStatusTillLCPreparation.scQty,
      "LC Preparation Time": FormatTime(item.previousStatusTillLCPreparation.lcPreparationTime),
      "LC Rejection Time": FormatTime(item.lcRejectionTime),
    }));

    setData(transformData);
  }, [allData]);

  return (
    <>
  
      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchToGetLcRejectionData} queryParams={queryParams} setQueryParams={setQueryParams} />
        <ExportInExcel data={data}  />
      </div>
      <AdminDataOfLcRejection queryParams={queryParams}/>
      <Pagination getData={fetchToGetLcRejectionData} totalData={totalNumberOfDataFetched} resultPerPage={resultPerPage} queryParams={queryParams} setQueryParams={setQueryParams}/>
      {/* <Pagination getData={fetchToGetLcRejectionData} totalData={totalNumberOfData} resultPerPage={resultPerPage} /> */}


    </>
  );
};

export default AdminLcRejection;
