import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToCreateNewMouldFilling } from '../../reduxStore/MouldFilling/MouldFillingSlice';
import { fetchToGetTypeOfMould } from '../../reduxStore/MouldFilling/TypeOfMouldSlice';
import QRScanner from '../../assets/QRScanner';

const CreateMouldFilling = () => {
    const dispatch = useDispatch();
    const [mouldFillingData, setMouldFillingData] = useState({
        bagId: "",
        filledBy: "",
        typeOfMould: "",
        mould: [
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },
            { mouldId: "", },

        ]
    });


    const handleInputDataOfMouldFilling = (key, value) => {
        setMouldFillingData({ ...mouldFillingData, [key]: value });
    };
    const handleInputDataOfMould = (index, key, value) => {
        setMouldFillingData((prevData) => {
            const newMouldData = [...prevData.mould]
            newMouldData[index][key] = value;
            return { ...mouldFillingData, mould: newMouldData }
        })
    };

    const submitFilledMouldFillingData = async (e) => {
        e.preventDefault();
        try {
            const res = await dispatch(fetchToCreateNewMouldFilling(mouldFillingData)).unwrap();
            if (res.success) {
                setMouldFillingData({
                    bagId: "",
                    filledBy: "",
                    typeOfMould: "",
                    mould: [
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },
                        { mouldId: "", },

                    ]
                });
            }

        } catch (error) {
            console.log(error);
        }
    };


    const clearAll = async () => {
        setMouldFillingData({
            bagId: "",
            filledBy: "",
            typeOfMould: "",
            mould: [
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },
                { mouldId: "", },

            ]
        });
    };




    return (
        <>
            <form onSubmit={submitFilledMouldFillingData} className="max-w-sm bg-white-50 p-4 sm:mx-auto mx-4 my-4 rounded-lg shadow-lg justify-center">
                <label htmlFor="bagId" className="block mt-2 text-sm font-medium text-teal-900">
                    Scan Bag ID:
                </label>
                <input
                    type="text" id="bagId" value={mouldFillingData.bagId} name="bagId"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="block w-full p-2 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500 dark:bg-white-700 dark:border-teal-600  dark:text-black dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder="Input Bag ID"
                    required
                />

                <label htmlFor="filledBy" className="block mt-2 text-sm font-medium text-teal-900">
                    Filled By:
                </label>
                <input
                    type="text" id="filledBy" value={mouldFillingData.filledBy} name="filledBy"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="block w-full p-2 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500 dark:bg-white-700 dark:border-teal-600  dark:text-black dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder="Input Filled By"
                    required
                />

                <label htmlFor={`typeOfMould`} className="block mt-2 text-sm font-medium text-teal-900">
                    Type of Mould:
                </label>
                <input
                    type="text"
                    id={`typeOfMould`}
                    value={mouldFillingData.typeOfMould}
                    name={`typeOfMould`}
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="block w-full p-2 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500 dark:bg-white-700 dark:border-teal-600 dark:text-black dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder={`Input Type of Mould`}
                    required
                />


                {mouldFillingData.mould.map((item, index) => (
                    <div key={index} className="mb-4">
                        <label htmlFor={`mouldId-${index}`} className="block mt-2 text-sm font-medium text-teal-900">
                            Scan Mould ID {index + 1}:
                        </label>
                        <input
                            type="text"
                            id={`mouldId-${index}`}
                            value={item.mouldId}
                            name={`mouldId-${index}`}
                            onChange={(e) => handleInputDataOfMould(index, 'mouldId', e.target.value)}
                            className="block w-full p-2 text-black-900 border border-teal-300 rounded-lg bg-white-50 text-base focus:ring-teal-500 focus:border-teal-500 dark:bg-white-700 dark:border-teal-600 dark:text-black dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder={`Input Mould ID ${index + 1}`}
                        />
                    </div>
                ))}




                <div className='py-4'>
                    <button
                        type="submit"
                        className="mr-2 text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Submit
                    </button>

                    <button
                        type="button"
                        onClick={() => clearAll()}
                        className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Clear All
                    </button>
                </div>
            </form>
        </>
    );
};

export default CreateMouldFilling;
