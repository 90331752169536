import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchToGetLoggedInUserDetails } from './reduxStore/authentication/userAuthSlice';
import Login from './assets/Login';
import Admin from './modules/Admin/Admin';
import NavigateInJamPreparation from './modules/JamPreparation/NavigateInJamPreparation';
import NavigateInLcPreparation from './modules/LcPreparation/NavigateInLcPreparation';
import NavigateInLcRejection from './modules/LcRejection/NavigateInLcRejection';
import NavigateInBagInoculation from './modules/BagInoculation/NavigateInBagInoculation';
import NavigateInBagStoreRoom from './modules/BagStoreRoom/NavigateInBagStoreRoom';
import NavigateInMouldFilling from './modules/MouldFilling/NavigateInMouldFilling';
import NavigateInMouldIncubation from './modules/MouldIncubation/NavigateInMouldIncubation';
import NavigateInDeMoulding from './modules/DeMouldingPieces/NavigateInDeMoulding.jsx';
import NavigateInRejectedDeMoulding from './modules/RejectedDeMoulding/NavigateInRejectedDeMoulding';
import NavigateGrowthChamber from './modules/GrowthChamber/NavigateGrowthChamber';
import NavigateInOvenIn from './modules/OvenIn/NavigateInOvenIn';
import NavigateInOvenOut from './modules/OvenOut/NavigateInOvenOut';

function App() {
  const state = useSelector((state) => state.userAuthReducer);
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    dispatch(fetchToGetLoggedInUserDetails());
  }, [dispatch , userRole]);

  useEffect(() => {
    setIsLoggedIn(!!state.user?.dhaDbTkn);
    setUserRole(state.user?.loggedInUser?.role);
  }, [state ,userRole]);

  const renderRoutes = () => {
    switch (userRole) {
      case "Admin":
        return <Admin />;
      case "Supervisor (Jam Preparation)":
        return <NavigateInJamPreparation />;
      case "Supervisor (LC Preparation)":
        return <NavigateInLcPreparation />;
      case "Supervisor (LC Rejection)":
        return <NavigateInLcRejection />;
      case "Supervisor (Bag Inoculation)":
        return <NavigateInBagInoculation />;
      case "Supervisor (Bag Incubation)":
        return <NavigateInBagStoreRoom />;
      case "Supervisor (Mould Filling)":
        return <NavigateInMouldFilling />;
      case "Supervisor (Mould Incubation)":
        return <NavigateInMouldIncubation />;
      case "Supervisor (De-Moulding)":
        return <NavigateInDeMoulding />;
      case "Supervisor (Growth Chamber)":
        return <NavigateGrowthChamber />;
      case "Supervisor (De-Moulding Rejection)":
        return <NavigateInRejectedDeMoulding />;
      case "Supervisor (Oven In)":
        return <NavigateInOvenIn />;
      case "Supervisor (Oven Out)":
        return <NavigateInOvenOut />;
    }
  };

  const loggedIn = async (dhaDbTkn , enteredRole) => {
    setIsLoggedIn(!!dhaDbTkn);
    await dispatch(fetchToGetLoggedInUserDetails());
    setUserRole(enteredRole)
    renderRoutes()
  }

  useEffect(() => {
    renderRoutes()
  }, [isLoggedIn, state, loggedIn,userRole]);


  return (
    <>
      {isLoggedIn ? renderRoutes() : <Login loggedIn={loggedIn}/>}
    </>
  );
}

export default App; 
