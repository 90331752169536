import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import FormatTime from '../../../assets/FormatTime';
import AdminDataOfRejectedDeMoulding from './AdminDataOfRejectedDeMoulding';
import { fetchToGetRejectedDeMouldData } from '../../../reduxStore/RejectedDeMoulding/RejectedDeMouldingSlice';

const AdminDeMouldingRejection = () => {
  const state = useSelector((state) => state.RejectedDeMouldingReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfDataFetched, setTotalNumberOfDataFetched] = useState(); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const [queryParams, setQueryParams] = useState({});

  const extraFilters = {
    "rejectedDeMouldId": "Rejected De-Mould Id",
    "deMouldingRejectedBy": "De-Mould Rejected By"
  };

  useEffect(() => {
    if (state && state.data && state.data.rejectedDeMouldData) {
      setAllData(state.data.allFilteredData);
      setTotalNumberOfDataFetched(state.data.totalNoOfDataFiltered || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "De-Mould ID": item.rejectedDeMouldId,
      "De-Moulding Rejection Date": FormatTime(item.rejectedDeMouldingTime),
      "De-Mould Rejected By": item.deMouldingRejectedBy,
      "Type Of Mould": item.previousDetailsTillMouldFilling?.typeOfMould,
      "Mould Filling Date": FormatTime(item.previousDetailsTillMouldFilling?.fillingDate),
      "Bag ID": item.previousDetailsTillMouldFilling?.bagId,
      "Bag Inoculation Date": FormatTime(item.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation?.inoculationTimeStamp),
      "LC/Jam ID": item.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation?.JamOrLcId,
      "LC/Jam Preparation Date": FormatTime(
        item.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation?.previousStatusTillJamOrLCPreparation?.jamPreparationTime ??
        item.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation?.previousStatusTillJamOrLCPreparation?.lcPreparationTime
      ),
    }));

    setData(transformData);
  }, [allData]);

  return (
    <>

      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchToGetRejectedDeMouldData} queryParams={queryParams} setQueryParams={setQueryParams} />
        <ExportInExcel data={data} />
      </div>
      <AdminDataOfRejectedDeMoulding queryParams={queryParams} />
      <Pagination getData={fetchToGetRejectedDeMouldData} totalData={totalNumberOfDataFetched} resultPerPage={resultPerPage} queryParams={queryParams} setQueryParams={setQueryParams} />
    </>
  );
};


export default AdminDeMouldingRejection
