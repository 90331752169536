import React, { useEffect, useState } from 'react'
import AdminDataOfMouldIncubation from './AdminDataOfMouldIncubation'
import { useSelector } from 'react-redux';
import { fetchToGetRejectedMoldData } from '../../../reduxStore/MouldIncubation/MouldIncubationSlice';
import FormatTime from '../../../assets/FormatTime';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';


const AdminMouldIncubation = () => {
  const state = useSelector((state) => state.mouldIncubationReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfDataFetched, setTotalNumberOfDataFetched] = useState(); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const [queryParams, setQueryParams] = useState({});
  
  
  const extraFilters = {
    "mouldId": "Mould ID",
    "reasonOfRejection": "Reason Of Rejection",
    "mouldRejectedBy":"Mould Rejected By",
  };


  useEffect(() => {
    if (state && state.data && state.data.mouldRejectedData) {
      setAllData(state.data.allFilteredData);
      setTotalNumberOfDataFetched(state.data.totalNoOfDataFiltered || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "Mould ID": item.mouldId,
      "Reason Of Rejection": item.reasonOfRejection,
      "Type of Mould": item.previousDetailsTillMouldFilling?.typeOfMould,
      "Mould Rejection Date": FormatTime(item.rejectionTime),
      "Mould Filling Date": FormatTime(item.previousDetailsTillMouldFilling?.fillingDate),
      "Mould Rejected By": item.mouldRejectedBy,
    }));

    setData(transformData);
  }, [allData]);


  return (
    <>




      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchToGetRejectedMoldData} queryParams={queryParams} setQueryParams={setQueryParams} />
        <ExportInExcel data={data} />
      </div>
      <AdminDataOfMouldIncubation queryParams={queryParams}/>
      <Pagination getData={fetchToGetRejectedMoldData} totalData={totalNumberOfDataFetched} resultPerPage={resultPerPage} queryParams={queryParams} setQueryParams={setQueryParams}/>



    </>
  )
}

export default AdminMouldIncubation
