import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import FillNewMould from './FillNewMould'
import DataOfMouldFilling from './DataOfMouldFilling'
import Logout from '../../assets/Logout'
import Login from '../../assets/Login'

const NavigateInMouldFilling = () => {
  
  return (
    <>
       <BrowserRouter>
      <Navbar moduleName={"Mould Filling"} home={"/"} viewData={"/viewDataOfMouldFilling"}/>
        <Routes>
          <Route path="/" element={<FillNewMould />}/>
          <Route path="/viewDataOfMouldFilling" element={< DataOfMouldFilling/>}/>
          <Route path="/myProfile" element={< ProfileCard />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/logout" element={<Logout />}/>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default NavigateInMouldFilling
