import React from 'react';
import { useNavigate } from 'react-router-dom';

const OvenOutNewFG = () => {
  const navigate = useNavigate()

    return (
        <>
        
            <div className='flex flex-wrap justify-center p-5'>
                
                    <div className={`m-2 w-60  bg-green-200 border border-l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">
                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900"> FG Type: T2 </h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                               
                                    <button type="button" onClick={() => {
                                        navigate('/definedQualityOfFg', { state: { quality: "T2" } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Scan T2 FG</button>

                            </div>
                        </div>
                    </div>
                    <div className={`m-2 w-60  bg-green-200 border border-l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">
                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900"> FG Type: TC </h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                               
                                    <button type="button" onClick={() => {
                                        navigate('/definedQualityOfFg', { state: { quality: "TC" } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Scan TC FG</button>

                            </div>
                        </div>
                    </div>
                    <div className={`m-2 w-60  bg-red-300 border border-l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">
                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900"> FG Type: T3</h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                               
                                    <button type="button" onClick={() => {
                                        navigate('/definedQualityOfFg', { state: { quality: "T3" } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Scan T3 FG</button>

                            </div>
                        </div>
                    </div>
             
            </div>

        </>
    );
};



export default OvenOutNewFG;
