import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHumidifiers } from '../../reduxStore/GrowthChamber/HumidifierSlice';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';


const Humidifier = () => {
    const [num, setNum] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const humidifiers = useSelector((state) => state.humidifierReducer);
    const [allDataFromCollection, getAllDataFromCollection] = useState();
    useEffect(() => {
        dispatch(fetchHumidifiers())
    }, [dispatch]);

    useEffect(() => {
        if (humidifiers.data && humidifiers.data.humidifiers) {
            getAllDataFromCollection(humidifiers.data.humidifiers)
        }
    }, [humidifiers]);


    const handleClean = async (h) => {
        // e.preventDefault();
        console.log(h)
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/humidifier?id=${h.serialNo}`;
            let data = {
                'status': 'empty'
            };
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                const errorData = await response.json();
                return errorData
            }

            const res = await response.json();
            return res;

        } catch (error) {
            console.error("Error in updating humidifier ", error);
            throw error;
        }
    }
    const [searchQuery, setSearchQuery] = useState('');


    const searchHumidifier = allDataFromCollection?.filter((currHum) => currHum.serialNo.includes(searchQuery))



    return (
        <>
            <div className='flex items-center  justify-between  w-full flex-column flex-wrap md:flex-row '>

                <div className=" sm:mx-1 sm:my-2 p-1 flex justify-center max-sm:w-full">
                    <h2 className='text-2xl sm:text-3xl font-bold m-4 text-center'>Humidifiers</h2>
                </div>

                <div className=" sm:mx-1 sm:my-2 p-1 flex justify-center max-sm:w-full">

                    <input
                        type="text"
                        id="table-search-users"
                        className=" p-2  text-sm text-black-900 border border-black-900 rounded-lg w-80  bg-black-50 focus:ring-black-500 focus:border-black  dark:bg-black-700 dark:border-black  dark:placeholder-black-400 dark:text-black dark:focus:ring-black-500 dark:focus:border-black "
                        placeholder="Search Humidifier"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

            </div>


            <hr />
            <div className='flex flex-wrap justify-center p-5'>

                {searchHumidifier?.map((h) => (
                    <div key={h.serialNo} className={`m-2 w-60  ${h.status === 'empty' && 'bg-green-200'} ${h.status === 'notFullyFilled' && 'bg-orange-200'} ${h.status === 'filledCompletely' && 'bg-blue-200'} ${h.status === 'readingCollected' && 'bg-pink-200'} ${h.status === 'clean' && 'bg-red-200'}  ${h.status === 'maintenance' && 'bg-red-200'} border border l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">

                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900">Humidifier: {h.serialNo} </h5>
                                <h5 className="text-md tracking-tight text-teal-900"> <span className='font-bold'>Type : </span>{h.type}</h5>
                                <h5 className="text-md tracking-tight text-teal-900"> <span className='font-bold'>Cycle Count : </span>{h.status == 'clean' ? 4 : h.cycleCount % 4}</h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                                {h.status === 'empty' &&
                                    <button type="button" onClick={() => {
                                        // generateNewBatchIdAndNavigate(h)
                                        navigate('/placeNewPieces', { state: { humidifier: h, humidifierNo: h.serialNo, dataId: h.dataId } })
                                    }} className="py-2 px-4 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4  focus:ring-blue-300 rounded-lg text-sm font-semibold text-center  " >
                                        Place Mould</button>
                                }

                                {h.status === 'filledCompletely' &&
                                    <button type="button" onClick={() => {
                                        navigate('/reading', { state: { humidifier: h } })
                                    }} className="py-2 px-4 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4  focus:ring-blue-300 font-semibold rounded-lg text-sm text-center " >
                                        Reading</button>}

                                {h.status === 'readingCollected' &&
                                    <button type="button" className="py-2 px-4 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4  focus:ring-red-300 font-semibold rounded-lg text-sm text-center " onClick={() => {
                                        navigate('/remove', { state: { humidifier: h } })
                                    }} >Remove</button>}

                                {h.status === 'clean' && (
                                    <button
                                        type="button"
                                        disabled
                                        className="py-2 px-4 text-red-600 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-semibold rounded-lg text-sm text-center cursor-not-allowed opacity-50"
                                    >
                                        Clean
                                    </button>
                                )}


                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
};

export default Humidifier;