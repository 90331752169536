import React, { useEffect, useState } from 'react';
import FormatTime from '../../assets/FormatTime';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DataOfOvenBatchWise = ({ batchID , serialNo }) => {

  const [retrieve, setRetrieve] = useState(false);
  const [ovenBatchData, setOvenBatchData] = useState();
  console.log("ovenBatchData",ovenBatchData);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/getDataOfOvenBatchWise?batchIdOfOvenIn=${batchID}&serialNo=${serialNo}`;

        const res = await fetch(url, {
          method: "GET",
          credentials: 'include'
        })
        if (!res.ok) {
          const errorData = await res.json();
          toast.error(errorData.message);
          return errorData
        }

        const data = await res.json();
        toast.success(data.message);
        setOvenBatchData(data)
        return data

      } catch (error) {
        console.log("Error in fetching data from oven and batch");

      }
    };
    fetchData();
  }, [retrieve]);




  return (
    <>

      <div className="relative overflow-x-auto shadow-md rounded-lg mx-2" >
        <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <caption className="p-5  font-bold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
          Oven: {ovenBatchData?.piecesData[0].ovenSerialNo}, Batch Id: {ovenBatchData?.piecesData[0].batchIdOfOvenIn}, Oven Temperature: {ovenBatchData?.piecesData[0].temp}, In Time {FormatTime(ovenBatchData?.piecesData[0].batchInTime)}
          </caption>
          <thead className="max-sm:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">S.No.</th>
              <th scope="col" className="px-6 py-3">Piece ID</th>
              <th scope="col" className="px-6 py-3">In Time</th>

              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Action</span>
              </th>
            </tr>
          </thead>

          <tbody className='max-sm:text-[13px]'>
            {ovenBatchData?.piecesData[0]?.ovenInSemiFGIds.reverse().map((currItem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{ovenBatchData?.piecesData[0]?.ovenInSemiFGIds.length - index }</th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{currItem.smFgId}</th>
                <th className="px-6 py-4">{FormatTime(currItem.inTime)}</th>
                <th className="px-6 py-4 text-right">
                  <button href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    Remove
                  </button>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </>
  );
};

export default DataOfOvenBatchWise;
