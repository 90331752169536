import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BatchesInOven = () => {
  const location = useLocation();

  const [ovenDetails, setOvenDetails] = useState(location.state.oven)
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const generateNewBatchID = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1; // Months are zero-based, so add 1
    let date = now.getDate();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    month = month < 10 ? `0${month}` : month;
    date = date < 10 ? `0${date}` : date;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const batchId = `${ovenDetails.serialNo}D${date}${month}${year}T${hours}${minutes}`;
    navigate('/placeMouldInOven', { state: { oven: ovenDetails, ovenSerialNo: ovenDetails.serialNo, batchId: batchId, tempRequired: true } })
  }


  const batchRemoveFromOven = async(serialNo,batchId)=>{
    try {
      const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
      const url = `${BACK_HOST}api/v1/batchRemoveFromOven?batchId=${batchId}&serialNo=${serialNo}`;
      const res = await fetch(url, {
          method: 'DELETE',
          credentials: 'include'
      })

      if (!res.ok) {
        const errorData = await res.json();
        toast.error(`Error: ${errorData.message}`);
        return errorData

    }
    const data = await res.json();
    toast.success(data.message);
    return data;
    }
    catch (error) {
      console.error('Error adding mould:', error);
  }
  }


  return (
    <>

      <div className="relative overflow-x-auto shadow-md rounded-lg mx-4 mt-2 mb-2 opacity-55">
        <div className="flex items-center justify-between w-full flex-column flex-wrap md:flex-row pb-4 bg-white dark:bg-teal-900">
          <div className="mx-6 my-2 text-white">
            <div className="text-xl font-bold">Oven: {ovenDetails.serialNo}</div>
          </div>
          <div className="relative mx-6 my-2 p-1 flex justify-end">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 ps-10 text-sm text-teal-900 border border-teal-300 rounded-lg w-80 bg-teal-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-teal-700 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Humidifier"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400">
            <thead className="text-xs text-gray-700 uppercase bg-teal-50 dark:bg-teal-700 dark:text-white">
              <tr>
                <th scope="col" className="px-6 py-3">S.No.</th>
                <th scope="col" className="px-6 py-3">Batch Ids</th>
                <th scope="col" className="px-6 py-3">Action</th>
              </tr>
            </thead>

            <tbody>




              {ovenDetails.batchInfo.reverse().map((currItem, currInd) => (
                <tr
                  className="bg-white border-b border-teal-200 dark:bg-white-800 dark:border-white-700 hover:bg-teal-100 dark:hover:bg-teal-100 hover:cursor-pointer"
                  key={currItem.batchId}
                  // onClick={() => navigate('/placeMouldInOven', { state: { oven: ovenDetails, ovenSerialNo: ovenDetails.serialNo, batchId: currItem.batchId } })}

                >
                  <td className="px-6 py-4"  
                  // onClick={() => navigate('/placeMouldInOven', { state: { oven: ovenDetails, ovenSerialNo: ovenDetails.serialNo, batchId: currItem.batchId } })}
                  >
                    {ovenDetails.batchInfo.length - currInd + 1}
                  </td>
                  <th scope="row" className="py-4 px-6 text-gray-900 whitespace-nowrap dark:text-black"
                   onClick={() => navigate('/placeMouldInOven', { state: { oven: ovenDetails, ovenSerialNo: ovenDetails.serialNo, batchId: currItem.batchId } })}
                  >
                    <div className="text-base font-semibold">
                      {currItem.batchId}
                    </div>
                  </th>

                  <td className="px-6 py-4" >
                    <div className="flex items-center">
                      
                      <button type="button" onClick={()=>batchRemoveFromOven(ovenDetails.serialNo,currItem.batchId )} className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                      Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))
              }

              <tr
                className="bg-white border-b border-teal-200 dark:bg-white-800 dark:border-white-700 hover:bg-teal-100 dark:hover:bg-teal-100 hover:cursor-pointer"

                onClick={generateNewBatchID}

              >
                <td className="px-6 py-4">
                  {1}
                </td>
                <th scope="row" className="py-4 px-6 text-gray-900 whitespace-nowrap dark:text-black">
                  <div className="text-base font-semibold">
                    Placed In New Batch
                  </div>
                </th>
                <td className="px-6 py-4">
                  <div className="flex items-center">

                  </div>
                </td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BatchesInOven


