import React, { useEffect, useState } from 'react';
import FormatTime from '../../assets/FormatTime';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const DataInHumidifier = ({ dataId ,humidifier }) => {

  // const [humidifier] = useState(false);
  const navigate = useNavigate();
  const [retrieve] = useState(false);
  const [humData, setHumData] = useState();
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/humidifier/dataInHumidifier/${dataId}`;

        const res = await fetch(url, {
          method: "GET",
          credentials: 'include'
        })
        if (!res.ok) {
          const errorData = await res.json();
          toast.error(errorData.message);
          return errorData
        }

        const data = await res.json();
        toast.success(data.message);
        setHumData(data)
        return data

      } catch (error) {
        console.log("Error in fetching data from oven and batch");

      }
    };
    fetchData();
  }, [retrieve]);
  

  const donePlacingMould = async () => {
    
      const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
      const url = `${BACK_HOST}api/v1/humidifier/filledCompletely/${humidifier._id}`;
      try {
        const response = await fetch(url, {
          method: 'PUT',
          credentials:'include'
        })
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message);
          return errorData
        }
  
        const res = await response.json();
        toast.success(res.message);
        navigate('/')
        return res;
  
      } catch (error) {
        console.error("Error in Humidifier filled completely ", error);
        throw error;
      }
    }




  return (
    <>

<div className="relative overflow-x-auto shadow-md rounded-lg mx-2">
  <caption className="p-5 font-bold text-left rtl:text-right text-teal-900 bg-teal-100 flex justify-between items-center rounded-t-lg">
    <div>Humidifier: {humData?.data.humidifierNo}</div>
    <div>
    <button type="button" onClick={()=>donePlacingMould()} class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Filled Humidifier</button>
    </div>
  </caption>
  <table className="w-full text-left rtl:text-right text-teal-700">
    <thead className="text-sm uppercase bg-teal-200 text-teal-900">
      <tr>
        <th scope="col" className="px-6 py-3">S.No.</th>
        <th scope="col" className="px-6 py-3">Piece ID</th>
        <th scope="col" className="px-6 py-3">
          <span className="sr-only">Action</span>
        </th>
      </tr>
    </thead>
    <tbody className="text-sm">
      {humData?.data.smFgData?.map((currItem, index) => (
        <tr
          className={`${
            index % 2 === 0 ? "bg-white" : "bg-teal-50"
          } border-b text-teal-900`}
          key={index}
        >
          <th
            scope="row"
            className="px-6 py-4 font-medium whitespace-nowrap"
          >
            {humData?.data.smFgData.length - index}
          </th>
          <th
            scope="row"
            className="px-6 py-4 font-medium whitespace-nowrap"
          >
            {currItem.smFgId}
          </th>
          <th className="px-6 py-4 text-right">
            <button
              href="#"
              className="font-medium text-teal-600 hover:text-teal-800"
            >
              Remove
            </button>
          </th>
        </tr>
      ))}
    </tbody>
  </table>
</div>


    </>
  );
};

export default DataInHumidifier;
