import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


const AllModuleDataChart = () => {
    const [allModuleDataCount, setAllModuleDataCount] = useState({});

    const formatDate = (date) => date.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [selectedButton, setSelectedButton] = useState('Today'); // Track the active button


    const handleToday = () => {
        const today = new Date();
        const formattedDate = formatDate(today);
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        setSelectedButton('Today');
        getAllModulesData(formattedDate, formattedDate);
    };

    const handleYesterday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedDate = formatDate(yesterday);
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        setSelectedButton('Yesterday');
        getAllModulesData(formattedDate, formattedDate);
    };
    const handleAllTime = () => {
        const dayOne = '2024-10-21';
        const today = new Date();
        const formattedDate = formatDate(today);
        setStartDate(dayOne);
        setEndDate(formattedDate);
        setSelectedButton('AllTime');
        getAllModulesData(dayOne, formattedDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            setSelectedButton('Custom');
            getAllModulesData(startDate, endDate);
        }
    };

    const getAllModulesData = async (startDate, endDate) => {
        const url = `${process.env.REACT_APP_BACKEND_PORT_AND_HOST}api/v1/admin/getDataOfAllModules?startDate=${startDate}&endDate=${endDate}`;
        const response = await fetch(url, {
            method: "GET",
            credentials: "include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error("Failed to fetch data of all modules");
        }
        const data = await response.json();
        // console.log("res data", data);
        setAllModuleDataCount(data.allModuleData)
        // return data;
    }

    useEffect(() => {
        getAllModulesData(startDate, endDate);
        //    console.log("startDate: ", startDate)
        //    console.log("endDate: ", endDate)
    }, []);

    const barChartData = {
        labels: ["Jam Preparation", "LC Preparation", "LC Rejection", "Bag Inoculation", "Bag Incubation", "Mould Filling", "Mold Incubation", "De-Moulding Pieces", "De-Moulding Rejection", "Humidifier", "Oven In", "Oven Out"],
        datasets: [
            {
                label: "Pieces made by different departments.",
                data: [
                    allModuleDataCount.totalCountDataOfPreparedJam || 0,
                    allModuleDataCount.totalCountDataOfPreparedLc || 0,
                    allModuleDataCount.totalCountDataOfRejectedLc || 0,
                    allModuleDataCount.totalCountDataOfBagInoculationData || 0,
                    allModuleDataCount.totalCountDataOfRejectedBag || 0,
                    allModuleDataCount.totalCountDataOfMouldFillingData || 0,
                    allModuleDataCount.totalCountDataOfMouldRejectedData || 0,
                    allModuleDataCount.totalCountDataOfDeMouldedPiece || 0,
                
                    allModuleDataCount.totalCountDataOfDeMouldedRejection || 0,
                    0,
                    allModuleDataCount.totalCountDataOfOvenInData || 0,
                    allModuleDataCount.totalCountDataOfOvenOutData || 0,
                ],
                backgroundColor: [

                    "rgba(255, 99, 132, 0.9)",
                    "rgba(54, 162, 235, 0.9)",
                    "rgba(153, 102, 255, 0.9)",
                    "rgba(255, 206, 86, 0.9)",
                    "rgba(75, 192, 192, 0.9)",
                    "rgba(255, 159, 64, 0.9)",
                    "rgba(201, 203, 207, 0.9)",
                    "rgba(105, 105, 105, 0.9)",
                    "rgba(255, 87, 51, 0.9)",
                    "rgba(105, 105, 105, 0.9)",
                    "rgba(255, 87, 51, 0.9)",
                    "rgba(100, 149, 237, 0.9)"



                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(255, 159, 64, 1)",
                    "rgba(201, 203, 207, 1)",
                    "rgba(105, 105, 105, 1)",
                    "rgba(255, 87, 51, 1)",
                    "rgba(105, 105, 105, 1)",
                    "rgba(255, 87, 51, 1)",
                    "rgba(100, 149, 237, 1)"
                ],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: true,
            },
        },
    }
    return (
        <>
            <div className="flex justify-center mt-2 p-4 my-4 mx-auto shadow border border-gray rounded">
                {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">All Modules</span> */}


                <div className="flex flex-wrap flex-center item-center">
                    <button
                        onClick={handleToday}
                        className={`
                                ${selectedButton === 'Today' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }`}
                    >
                        Today
                    </button>

                    <button
                        onClick={handleYesterday}
                        className={`
                        ${selectedButton === 'Yesterday' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }`}
                    >
                        Yesterday
                    </button>

                    <button
                        onClick={handleAllTime}
                        className={`
                            ${selectedButton === 'AllTime' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }
                        `}
                    >
                        All Time
                    </button>

                    <div className="flex">

                        <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">Starting Date:</span>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={`
                        ${selectedButton === 'Custom' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                        }`}
                        />
                    </div>

                    <div className="flex">

                        <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">End Date:</span>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className={`
                        ${selectedButton === 'Custom' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }`}
                        />
                    </div>

                    <button type="button"
                        onClick={handleSubmit}
                        className={`${selectedButton === 'Custom' ?
                            ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                            : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'}`}>
                        Apply
                    </button>


                </div>

            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">All Modules</span> */}
                <table className="w-full text-sm text-left text-gray-700">
                    <thead className="text-xs uppercase bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Jam Preparation
                            </th>
                            <th scope="col" className="px-6 py-3">
                                LC Preparation
                            </th>
                            <th scope="col" className="px-6 py-3">
                                LC Rejection
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Bag Inoculation
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Bag Incubation
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Mould Filling
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Mold Incubation
                            </th>
                            <th scope="col" className="px-6 py-3">
                                De-Moulding Pieces
                            </th>
                            <th scope="col" className="px-6 py-3">
                                De-Moulding Rejection
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Humidifier
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Oven In
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Oven Out
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total
                            </th>

                        </tr>
                    </thead>
                    <tbody>


                        <tr className="bg-white">

                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfPreparedJam || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfPreparedLc || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfRejectedLc || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfBagInoculationData || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfRejectedBag || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfMouldFillingData || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfMouldRejectedData || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfDeMouldedPiece || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfDeMouldedRejection || 0}</td>
                            <td className="px-6 py-4">{0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfOvenInData || 0}</td>
                            <td className="px-6 py-4">{allModuleDataCount.totalCountDataOfOvenOutData || 0}</td>


                            <td className="px-6 py-4">
                                {
                                    allModuleDataCount?.totalCountDataOfPreparedJam +
                                    allModuleDataCount?.totalCountDataOfPreparedLc +
                                    allModuleDataCount?.totalCountDataOfRejectedLc +
                                    allModuleDataCount?.totalCountDataOfBagInoculationData +
                                    allModuleDataCount?.totalCountDataOfRejectedBag +
                                    allModuleDataCount?.totalCountDataOfMouldFillingData +
                                    allModuleDataCount?.totalCountDataOfMouldRejectedData +
                                    allModuleDataCount?.totalCountDataOfDeMouldedPiece +
                                    allModuleDataCount?.totalCountDataOfDeMouldedRejection +
                                    0 +
                                    allModuleDataCount?.totalCountDataOfOvenInData +
                                    allModuleDataCount?.totalCountDataOfOvenOutData
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className=" p-4 my-4 mx-auto shadow border border-gray rounded relative ">
                {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">All Modules</span> */}
                {/* <div className="flex justify-center mt-2">


                    <div className="flex flex-wrap flex-center item-center">
                        <button
                            onClick={handleToday}
                            className={`
                                ${selectedButton === 'Today' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }
                            `}
                        >
                            Today
                        </button>
                        <button
                            onClick={handleYesterday}
                            className={`
                                ${selectedButton === 'Yesterday' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }
                            `}
                        >
                            Yesterday
                        </button>

                        <button
                            onClick={handleAllTime}
                            className={`
                                ${selectedButton === 'AllTime' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }
                            `}
                        >
                            All Time</button>

                        <div className="flex">

                            <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">Starting Date:</span>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className={`
                                    ${selectedButton === 'Custom' ?
                                        ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                        : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                    }
                                `}
                            />
                        </div>

                        <div className="flex">

                            <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">End Date:</span>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className={`
                                    ${selectedButton === 'Custom' ?
                                        ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                        : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                    }
                                `}
                            />
                        </div>

                        <button type="button"
                            onClick={handleSubmit}
                            className={`
                            ${selectedButton === 'Custom' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }
                        `}
                        >Apply</button>
                    </div>

                </div> */}


                <div className="flex justify-center max-h-[500px]">
                    <Bar options={options} data={barChartData} />
                </div>




                <NavLink to="/admin/dashboard" className={({ isActive }) =>
                    isActive ? "hidden" : "absolute bottom-0 right-0 m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                }
                >
                    Analytics
                    <svg className="ml-2 rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </NavLink>
            </div>


        </>
    )
}

export default AllModuleDataChart




























//!old code (if above code is running then you can remove it)

// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { fetchDataOfAllModules } from "../../../reduxStore/Dashboard/DashboardSlice";
// import { NavLink } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// )


// const AllModuleDataChart = () => {
//     // const state = useSelector((state) => state.dashboardReducer);
//     // const dispatch = useDispatch();
//     const [allModuleDataCount, setAllModuleDataCount] = useState({});

//     const formatDate = (date) => date.toISOString().split('T')[0];

//     const [startDate, setStartDate] = useState(formatDate(new Date()));
//     const [endDate, setEndDate] = useState(formatDate(new Date()));
//     const [selectedButton, setSelectedButton] = useState('Today'); // Track the active button


//     const handleToday = () => {
//         const today = new Date();
//         const formattedDate = formatDate(today);
//         setStartDate(formattedDate);
//         setEndDate(formattedDate);
//         setSelectedButton('Today');
//         getAllModulesData(formattedDate, formattedDate);
//     };

//     const handleYesterday = () => {
//         const yesterday = new Date();
//         yesterday.setDate(yesterday.getDate() - 1);
//         const formattedDate = formatDate(yesterday);
//         setStartDate(formattedDate);
//         setEndDate(formattedDate);
//         setSelectedButton('Yesterday');
//         getAllModulesData(formattedDate, formattedDate);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (startDate && endDate) {
//             setSelectedButton('Custom');
//             getAllModulesData(startDate, endDate);
//         }
//     };

//     const getAllModulesData = async (startDate, endDate) => {
//         const url = `${process.env.REACT_APP_BACKEND_PORT_AND_HOST}api/v1/admin/getDataOfAllModules?startDate=${startDate}&endDate=${endDate}`;
//         const response = await fetch(url, {
//             method: "GET",
//             credentials: "include"
//         });

//         if (!response.ok) {
//             const errorData = await response.json();
//             toast.error(errorData.message);
//             throw new Error("Failed to fetch data of all modules");
//         }
//         const data = await response.json();
//         console.log("res data", data);
//         setAllModuleDataCount(data.allModuleData)
//         // return data;
//     }

//     useEffect(() => {
//         getAllModulesData(startDate, endDate);
//         //    console.log("startDate: ", startDate)
//         //    console.log("endDate: ", endDate)
//     }, []);

//     const barChartData = {
//         labels: ["Jam Preparation", "LC Preparation", "LC Rejection", "Bag Inoculation", "Bag Incubation", "Mould Filling", "Mold Incubation", "De-Moulding Rejection", "Oven In", "Oven Out"],
//         datasets: [
//             {
//                 label: "Pieces made by different departments.",
//                 data: [
//                     allModuleDataCount.totalCountDataOfPreparedJam || 0,
//                     allModuleDataCount.totalCountDataOfPreparedLc || 0,
//                     allModuleDataCount.totalCountDataOfRejectedLc || 0,
//                     allModuleDataCount.totalCountDataOfBagInoculationData || 0,
//                     allModuleDataCount.totalCountDataOfRejectedBag || 0,
//                     allModuleDataCount.totalCountDataOfMouldFillingData || 0,
//                     allModuleDataCount.totalCountDataOfMouldRejectedData || 0,
//                     allModuleDataCount.totalCountDataOfDeMouldingRejection || 0,
//                     allModuleDataCount.totalCountDataOfOvenInData || 0,
//                     allModuleDataCount.totalCountDataOfOvenOutData || 0,
//                 ],
//                 backgroundColor: [

//                     "rgba(255, 99, 132, 0.9)",
//                     "rgba(54, 162, 235, 0.9)",
//                     "rgba(153, 102, 255, 0.9)",
//                     "rgba(255, 206, 86, 0.9)",
//                     "rgba(75, 192, 192, 0.9)",
//                     "rgba(255, 159, 64, 0.9)",
//                     "rgba(201, 203, 207, 0.9)",
//                     "rgba(105, 105, 105, 0.9)",
//                     "rgba(255, 87, 51, 0.9)",
//                     "rgba(100, 149, 237, 0.9)"



//                 ],
//                 borderColor: [
//                     "rgba(255, 99, 132, 1)",
//                     "rgba(54, 162, 235, 1)",
//                     "rgba(153, 102, 255, 1)",
//                     "rgba(255, 206, 86, 1)",
//                     "rgba(75, 192, 192, 1)",
//                     "rgba(255, 159, 64, 1)",
//                     "rgba(201, 203, 207, 1)",
//                     "rgba(105, 105, 105, 1)",
//                     "rgba(255, 87, 51, 1)",
//                     "rgba(100, 149, 237, 1)"
//                 ],
//                 borderWidth: 1,
//             },
//         ]
//     };

//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: "bottom",
//             },
//             title: {
//                 display: true,
//             },
//         },
//     }
//     return (
//         <>



//             <div className=" p-4 my-4 shadow border border-gray rounded relative ">
//                 <div className="flex justify-between">


//                 </div>


//                 <div className="flex justify-between hidden">


//                     <div className="font-bold text-lg text-black ">All Modules</div>


//                     <div className="flex justify-center items-center p-2 border rounded-lg bg-gray-100">


//                         <div className="p-2 border rounded-lg bg-gray-100 flex space-x-2">
//                             <button
//                                 onClick={handleToday}
//                                 className={`py-2 px-4 rounded-lg ${selectedButton === 'Today' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
//                                     }`}
//                             >
//                                 Today
//                             </button>
//                             <button
//                                 onClick={handleYesterday}
//                                 className={`py-2 px-4 rounded-lg ${selectedButton === 'Yesterday' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
//                                     }`}
//                             >
//                                 Yesterday
//                             </button>

//                             <form onSubmit={handleSubmit} className="flex space-x-4">
//                                 <label className="flex items-center">
//                                     <span className="mr-2">Starting Date:</span>
//                                     <input
//                                         type="date"
//                                         value={startDate}
//                                         onChange={(e) => setStartDate(e.target.value)}
//                                         className="py-2 px-4 rounded-lg bg-gray-200 text-gray-600"
//                                         required
//                                     />
//                                 </label>
//                                 <label className="flex items-center">
//                                     <span className="mr-2">End Date:</span>
//                                     <input
//                                         type="date"
//                                         value={endDate}
//                                         onChange={(e) => setEndDate(e.target.value)}
//                                         className="py-2 px-4 rounded-lg bg-gray-200 text-gray-600"
//                                         required
//                                     />
//                                 </label>
//                                 <button
//                                     type="submit"
//                                     className={`py-2 px-4 rounded-lg ${selectedButton === 'Custom' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'
//                                         }`}
//                                 >
//                                     Apply
//                                 </button>
//                             </form>




//                         </div>

//                     </div>
//                 </div>

//                 <div className="flex justify-center max-h-[500px]">
//                     <Bar options={options} data={barChartData} />
//                 </div>



//                 <NavLink to="/admin/dashboard" className={({ isActive }) =>
//                     isActive ? "hidden" : "absolute bottom-0 right-0 m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//                 }
//                 >
//                     Analytics
//                     <svg className="ml-2 rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//                         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
//                     </svg>
//                 </NavLink>

//             </div>
//         </>
//     )
// }

// export default AllModuleDataChart